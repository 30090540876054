<template>
  <BaseButton
    :disabled="assignmentIds.length === 0"
    label="Delete selected assignments"
    variant="link"
    size="medium"
    @click="() => (showDialogue = true)"
  >
    <template #leftIcon>
      <BaseTooltip
        description="Delete selected assignments"
        :options="{ placement: 'right', offset: 10 }"
      >
        <TrashIcon />
      </BaseTooltip>
    </template>
  </BaseButton>

  <BaseDialog
    v-if="showDialogue"
    overlay
    dismissible
    open
    :title="`Confirm deletion of ${countToDelete}?`"
    @close="closeDialogue"
    @submit.prevent="bulkDeleteAssignments"
  >
    <BaseAlert variant="warning" inline expanded>
      This is a permanent action which cannot be undone
    </BaseAlert>
    <template #actions>
      <BaseLoadingSpinner v-if="deleteInProgress" inline>
        Deleting
      </BaseLoadingSpinner>
      <template v-else>
        <BaseButton variant="danger" type="submit">
          Delete {{ countToDelete }}
        </BaseButton>
        <BaseButton type="submit" @click="closeDialogue"> Cancel </BaseButton>
      </template>
    </template>
  </BaseDialog>
</template>

<script lang="ts" setup>
import { computed, ref, toRefs } from 'vue';
import { useQueryClient } from '@tanstack/vue-query';

import TrashIcon from '@patchui/icons/TrashCan.vue';
import BaseAlert from '@patchui/productcl/components/BaseAlert/BaseAlert.vue';
import BaseButton from '@patchui/productcl/components/BaseButton/BaseButton.vue';
import BaseDialog from '@patchui/productcl/components/BaseDialog/BaseDialog.vue';
import BaseLoadingSpinner from '@patchui/productcl/components/BaseLoadingSpinner/BaseLoadingSpinner.vue';
import BaseTooltip from '@patchui/productcl/components/BaseTooltip/BaseTooltip.vue';
import { useToast } from '../../../../hooks/useToast';
import { deleteOrgAssignments } from '../../../../api/assignments';
import { selectedOrg } from '../../assignmentUtils';

export interface DeleteButtonProps {
  assignmentIds: string[];
}
const props = defineProps<DeleteButtonProps>();
const { assignmentIds } = toRefs(props);
const toast = useToast();
const queryClient = useQueryClient();

const showDialogue = ref(false);
const deleteInProgress = ref(false);

const emit = defineEmits<{
  (e: 'assignmentsDeletionComplete'): void;
}>();

const countOfAssignmentText = (count: number) =>
  count === 1 ? '1 assignment' : `${count} assignments`;
const countToDelete = computed(() =>
  countOfAssignmentText(assignmentIds.value.length),
);

const closeDialogue = () => (showDialogue.value = false);

const bulkDeleteAssignments = async () => {
  deleteInProgress.value = true;
  try {
    const orgId = selectedOrg.value?.id;
    if (!orgId) {
      throw new Error('No org selected'); // Should never happen, required for type safety
    }
    await deleteOrgAssignments(orgId, assignmentIds.value);

    toast.success(
      `${countOfAssignmentText(assignmentIds.value.length)} deleted`,
    );
    queryClient.refetchQueries();
    emit('assignmentsDeletionComplete'); // Emit the event after successful deletion
  } catch (err) {
    toast.error(`Could not delete selected assignments`);
  } finally {
    closeDialogue();
    deleteInProgress.value = false;
  }
};
</script>
