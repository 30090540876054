<template>
  <section
    class="assignments-list"
    :class="{ 'assignments-list__refetching': isRefetching }"
  >
    <div class="assignments-list__top-bar">
      <AssignmentFilters :mapped-assignments="mappedAssignments" />
      <DownloadAssignmentsButton :mapped-assignments="filteredAndSortedRows" />
    </div>
    <div class="assignments-list__action-bar">
      <BaseTooltip
        description="Select all visible assignments"
        label
        :options="{ placement: 'bottom-start', offset: 10 }"
      >
        <BaseCheckbox
          v-model="selectAllState"
          @update:model-value="onBulkSelect"
        />
      </BaseTooltip>
      <EditAssignmentsButton
        :assignment-ids="checkedAssignmentIds"
        @assignments-update-complete="clearCheckedItems"
      />
      <DeleteAssignmentsButton
        :assignment-ids="checkedAssignmentIds"
        @assignments-deletion-complete="clearCheckedItems"
      />
    </div>
    <BaseAlert
      v-if="checkedAssignmentIds.length && selectAllState"
      inline
      expanded
      name="selected-assignments-count"
    >
      <strong>{{ checkedAssignmentIds.length }}</strong> assignments selected of
      {{ filteredAssignments.length }}
    </BaseAlert>
    <BaseTable
      class="assignments-list__table"
      title="Assignments"
      :columns="[...columns]"
      :rows="paginatedSortedAndFilteredRows"
      :initial-sort-field="defaultSortField"
      :initial-sort-direction="defaultSortDirection"
      :server-sort="true"
      :loading="assignmentsLoading"
      :loading-rows="pageSize"
      @update:sort="onTableSort"
    >
      <template #lessonTitle="{ row }">
        <label
          class="assignments-list__checkbox-cell"
          :for="'checkbox-' + row.assignmentId"
        >
          <BaseCheckbox
            :id="'checkbox-' + row.assignmentId"
            v-model="checkedItems[row.assignmentId]"
          />
          {{ row.lessonTitle }}
        </label>
      </template>
      <template #assignmentStatus="{ row }">
        <DueDateBadge
          :key="dueDateBadgeKey(row).value"
          :status="row.assignmentStatus"
          :due-in-days="row.daysUntilAssignmentDue"
        />
      </template>
      <template #createdDate="{ row }">
        <div>
          {{ mapRowForPrinting(row).createdDate }}
        </div>
      </template>
      <template #dueDate="{ row }">
        {{ mapRowForPrinting(row).dueDate }}
      </template>
      <template #completedDate="{ row }">
        {{ mapRowForPrinting(row).completedDate }}
      </template>
      <template #footer>
        <Pagination
          :page-number="pageNumber"
          :page-size="pageSize"
          :total-record-count="filteredAssignments.length"
          @setPage="onPageChange"
        />
      </template>
      <template #empty>
        <EmptyState :org-id="props.orgId" />
      </template>
    </BaseTable>
  </section>
</template>

<script lang="ts" setup>
import { computed, ref, toRefs, reactive } from 'vue';
import BaseTable from '@patchui/productcl/components/BaseTable/BaseTable.vue';
import BaseCheckbox from '@patchui/productcl/components/BaseCheckbox/BaseCheckbox.vue';
import DueDateBadge from '../../../../components/DueDateBadge/DueDateBadge.vue';
import BaseTooltip from '@patchui/productcl/components/BaseTooltip/BaseTooltip.vue';
import BaseAlert from '@patchui/productcl/components/BaseAlert/BaseAlert.vue';
import DownloadAssignmentsButton from './DownloadAssignmentsButton.vue';
import DeleteAssignmentsButton from './DeleteAssignmentsButton.vue';
import EditAssignmentsButton from './EditAssignmentsButton.vue';
import Pagination from './Pagination.vue';
import AssignmentFilters from './AssignmentFilters.vue';
import EmptyState from './EmptyState.vue';
import { typedObjectKeys } from '../../../../lib/utils/type-utilities';
import {
  columns,
  mapAssignmentToRow,
  ASSIGNMENT_DASHBOARD_ITLY_ORIGIN,
  selectedFilters,
  mapRowForPrinting,
  isValidColumnDataKey,
  createAssignmentsSorter,
} from './tableUtils';
import itly from '../../../../lib/analytics/itly';

import type { LessonAssignmentDecoratedResource } from '../../../../api/assignments';
import type { MappedAssignment } from './tableUtils';

export interface TableProps {
  assignments: LessonAssignmentDecoratedResource[];
  assignmentsLoading: boolean;
  orgId: string;
  isRefetching: boolean;
}
const props = defineProps<TableProps>();
const { assignments, assignmentsLoading, isRefetching } = toRefs(props);
const checkedItems: Record<string, boolean> = reactive({});
const checkedAssignmentIds = computed(() => {
  return Object.entries(checkedItems)
    .filter(([_, value]) => value)
    .map(([key]) => key);
});

const dueDateBadgeKey = (row: MappedAssignment) =>
  computed(() => {
    return `${row.assignmentId}_days${row.daysUntilAssignmentDue}`;
  });

const defaultSortField = 'createdDate';
const defaultSortDirection = 'DESC';
const sortField = ref<keyof MappedAssignment>(defaultSortField);
const sortDirection = ref<'ASC' | 'DESC'>(defaultSortDirection);
const pageNumber = ref(1);
const pageSize = 10;

const mappedAssignments = computed(() => {
  return assignments.value.map(mapAssignmentToRow);
});

const filteredAssignments = computed(() => {
  return mappedAssignments.value.filter((row) => {
    const fieldKeys = typedObjectKeys(selectedFilters.value);
    return fieldKeys.every((key) => {
      const filterValues = selectedFilters.value[key];
      if (!filterValues || filterValues.length === 0) return true;
      const filterType = columns.find(
        (column) => column.dataKey === key,
      )?.filterType;

      if (filterType === 'dateRange') {
        if (!filterValues || filterValues.length !== 2) return true;
        const [start, end] = filterValues as number[];
        const startStartDate = new Date(start).setHours(0, 0, 0, 0).valueOf();
        const endOfEndDate = new Date(end).setHours(23, 59, 59, 999).valueOf();

        const rowValue = row[key] as number | undefined;
        if (!rowValue) return false;
        return rowValue > startStartDate && rowValue < endOfEndDate;
      } else {
        return filterValues.includes(row[key] || '');
      }
    });
  });
});

const filteredAndSortedRows = computed(() => {
  const sorter = createAssignmentsSorter(sortField.value, sortDirection.value);
  return filteredAssignments.value.slice().sort(sorter);
});

const paginatedSortedAndFilteredRows = computed(() => {
  return filteredAndSortedRows.value.filter((_, i: number) => {
    const start = (pageNumber.value - 1) * pageSize;
    const end = start + pageSize;
    return i >= start && i < end;
  });
});

const onPageChange = (newPageNumber: number) => {
  clearCheckedItems();
  pageNumber.value = newPageNumber;
};

const selectAllState = computed(() => {
  const allVisibleRowIds = paginatedSortedAndFilteredRows.value.map(
    (row) => row.assignmentId,
  );
  if (allVisibleRowIds.every((id) => checkedItems[id])) return true;
  if (allVisibleRowIds.some((id) => checkedItems[id])) return null; // Indeterminate state
  return false;
});

const clearCheckedItems = () => {
  for (const key of Object.keys(checkedItems)) {
    delete checkedItems[key];
  }
};
const onBulkSelect = (newValue: boolean) => {
  const allVisibleRowIds = paginatedSortedAndFilteredRows.value.map(
    (row) => row.assignmentId,
  );
  clearCheckedItems();
  if (newValue) {
    for (const id of allVisibleRowIds) {
      checkedItems[id] = true;
    }
  }
};

interface SortOptions {
  sortBy: string;
  sortDirection: 'ASC' | 'DESC';
}

const onTableSort = (sortOptions: SortOptions) => {
  if (isValidColumnDataKey(sortOptions.sortBy)) {
    sortField.value = sortOptions.sortBy;
  }
  sortDirection.value = sortOptions.sortDirection;

  itly.learnTableIsManipulated({
    eventSource: 'Learn',
    path: '/admin/assignments/',
    origin: ASSIGNMENT_DASHBOARD_ITLY_ORIGIN,
    action: 'Sort',
  });
};
</script>

<style lang="scss" scoped>
.assignments-list {
  margin-top: 30px;
  &__top-bar {
    display: flex;
    justify-content: space-between;
    margin-bottom: 14px;
  }
  &__action-bar {
    display: flex;
    margin-bottom: 14px;
    margin-left: 17px;
    align-items: center;
  }
  &__table {
    border-collapse: separate;
  }

  &__checkbox-cell {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
    max-width: 300px;
  }

  &__refetching {
    opacity: 0.5;
  }
}

:deep(tfoot) {
  span {
    font-style: normal; // Footer is italic by default
  }
}
</style>
