import { unauthenticatedClient } from '../lib/apiClient';
import { isContentPreviewActive } from '../lib/utils/isContentPreview';
import type { components } from './learn-backend-hidden-schema';
import type { RestApiResponse } from '../lib/utils/type-utilities';
import { AxiosError } from 'axios';
import { useQuery } from '@tanstack/vue-query';
import { QueryMetaExtended } from '../types';

export const LIST_CATALOG_KEY = 'listCatalog';

// When updating the version below please remember to update the version in generate-sitemap.ts
export const LIST_CATALOG_VERSION = '2024-10-15';
export const PREVIEW_EDUCATION_RESOURCES_VERSION = '2024-03-03~experimental';

export const listCatalog = async (): Promise<CatalogItem[]> => {
  let requestUrl = `/rest/learn/catalog?version=${LIST_CATALOG_VERSION}`;
  if (isContentPreviewActive()) {
    requestUrl = `/hidden/preview_education_resources?version=${PREVIEW_EDUCATION_RESOURCES_VERSION}&content_source=source-preview`;
  }
  const response = await unauthenticatedClient.get<
    RestApiResponse<'listLearnCatalog'>
  >(requestUrl);
  return response.data.data;
};

// alias for backward compatibility
export type EducationResource = components['schemas']['EducationResource'];
export type CatalogItem = components['schemas']['EducationResource'];

export const useCatalog = () => {
  return useQuery<CatalogItem[], AxiosError>({
    queryKey: [LIST_CATALOG_KEY],
    queryFn: listCatalog,
    staleTime: Infinity,
    meta: {
      isContentQuery: true,
    } as QueryMetaExtended,
  });
};
